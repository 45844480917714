import { modules } from "@/helpers/variables/modules";

export function getModule(nameModule) {
  let selectModule = "";
  switch (nameModule) {
    case "Perfil do usuário":
      selectModule = modules.suite;
      break;
    case "Conecta Track":
      selectModule = modules.track;
      break;
    case "Aplicação em massa":
      selectModule = modules.sign;
      break;
    case "Conecta Control":
      selectModule = modules.control;
      break;
    case "Conecta Control DEV":
      selectModule = modules.control;
      break;
    case "Conecta Transfer":
      selectModule = modules.transfer;
      break;
    case "Conecta Support":
      selectModule = modules.support;
      break;
    case "Conecta Partners":
      selectModule = modules.reseller;
      break;
    case "Permissões de Usuários":
      selectModule = modules.manageAdmins;
      break;
    case "Inventário digital":
      selectModule = modules.inventory;
      break;
    case "Perfil da Empresa":
      selectModule = modules.companyProfile;
      break;
    case "Faturamento":
      selectModule = modules.billing;
      break;
    case "Relatórios":
      selectModule = modules.reports;
      break;
  }
  return selectModule;
}
