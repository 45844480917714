<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple class="ma-4 ms-1">
      <v-expansion-panel class="outlined">
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <v-row align="center">
            <v-col cols="9" class="">
              {{ $t("emailMigration.title") }}
              <v-chip color="blue" dark x-small class="font-weight-medium ml-3"
                >Beta
              </v-chip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content style="position: relative">
          <v-progress-linear
            v-if="loagingScopes"
            indeterminate
            color="primary"
            width="4"
          ></v-progress-linear>
          <div v-else>
            <v-card-text class="mx-0 px-0 pt-0">
              {{ $t("emailMigration.subtitle") }}
            </v-card-text>

            <p class="mt-4">
              {{ $t("emailMigration.selectGroupTip") }}
              <span class="font-weight-medium">{{ user.email }}</span>
            </p>

            <v-form
              @submit.prevent=""
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="groupEmail"
                    :items="groupEmails"
                    :placeholder="'meugrupo@' + mainDomain"
                    class="mt-0"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="selectedDateOption"
                    :items="options"
                    :label="$t('emailMigration.migrateEmailsFromDate')"
                    item-text="text"
                    item-value="value"
                    item-disabled="disabled"
                    @change="handleOptionChange"
                    required
                    :hint="
                      selectedDateOption === 'custom'
                        ? $t('emailMigration.startMigrationDateDescription')
                        : ''
                    "
                  >
                    <template v-slot:append-item v-if="companyIsInTrial">
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item
                        v-for="item in [
                          'emailMigration.start.pastYear',
                          'emailMigration.start.customPeriod',
                        ]"
                        :key="item"
                        class="list-item"
                        @click="goToSelfCheckout"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t(item) }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $t("common.availableFromSecurity") }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            elevation="0"
                            small
                            color="accent"
                            class="text-none"
                            text
                          >
                            {{ $t("action.seePlans") }}
                            <v-icon right color="accent">mdi-crown</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="4">
                  <v-menu
                    v-if="selectedDateOption === 'custom'"
                    ref="datePickerMenu"
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatedCustomDate"
                        :label="$t('emailMigration.start.selectStartDate')"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="customDate"
                      no-title
                      scrollable
                      min="2000-01-01"
                      :max="maxDate"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="datePickerMenu = false"
                        >{{ $t("action.cancel") }}</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePickerMenu.save(customDate)"
                        >{{ $t("action.ok") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <span class="text-subtitle-2 grey--text text--darken-2">
                    {{ $t("emailMigration.includeTrashTip") }}
                  </span>
                  <div class="d-flex align-center">
                    <v-checkbox
                      v-model="includeTrash"
                      :label="$t('emailMigration.includeTrashLabel')"
                    ></v-checkbox>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right color="blue" v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>{{ $t("emailMigration.trashEmailTooltip") }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
              </v-row>

              <v-tooltip :disabled="userHasGmail" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: fit-content" v-bind="attrs" v-on="on">
                    <v-btn
                      :disabled="
                        !groupEmail ||
                        loading ||
                        !valid ||
                        !userHasGmail ||
                        (selectedDateOption === 'custom' && !customDate)
                      "
                      :loading="loading"
                      color="accent"
                      class="mt-4 elevation-0 text-none font-weight-medium text-body-2 white--text"
                      @click="openDialog"
                    >
                      {{ $t("emailMigration.initMigration") }}
                    </v-btn>
                  </div>
                </template>
                <span v-if="!userHasGmail">
                  {{ $t("common.inactiveUserInWorkspaceAlert") }}
                </span>
              </v-tooltip>
            </v-form>
          </div>

          <!-- DIALOG ADICIONAR NOVO USER -->
          <HeaderDialog
            id="migrate_email"
            :title="user?.email || ''"
            subtitle="Migração de e-mails para grupos"
            :actionText="$t('emailMigration.initMigration')"
            :show="dialog"
            width="700"
            :loading="loading"
            @action="initMigration()"
            :disabled-action="loading"
            @close="dialog = false"
            :persistent="false"
          >
            <template v-slot:toolbar-actions>
              <v-chip color="success" dark class="font-weight-medium mr-1"
                >Beta
              </v-chip>
            </template>

            <template v-slot:body>
              <Alert class="mt-4 py-2" :prominent="false" color="info" dense>
                {{ $t("emailMigration.dialog.alert") }}
              </Alert>

              <div class="ml-2">
                <div>
                  <p>
                    {{ $t("emailMigration.dialog.timeToFinishInstruction") }}
                    {{
                      dialogMessage ||
                      $t("emailMigration.dialog.closePageInstruction")
                    }}
                  </p>
                </div>
              </div>
              <p class="ml-2">
                {{ $t("emailMigration.dialog.maxMailSizeTip") }}
              </p>
            </template>
          </HeaderDialog>
          <!-- OVERLAY FOR TASKS RUNNING -->
          <v-overlay
            :absolute="true"
            color="primary"
            :value="overlay"
            opacity="0.8"
            class="pa-5"
          >
            <div class="d-flex flex-column align-center justify-center">
              <v-card-title> {{ $t("emailMigration.title") }} </v-card-title>
              <v-card-text
                v-if="hasEmailMigrationTaskRunning"
                class="font-weight-medium text-center pb-6 mb-6"
              >
                {{ $t("emailMigration.overlay.migrationRunning") }}
              </v-card-text>
              <v-card-text
                v-else
                class="font-weight-medium text-center pb-6 mb-6"
              >
                {{ $t("emailMigration.overlay.missingScopesAlert") }}
                <span v-if="isAdmin">
                  {{ $t("scopesAlert.requestToAcceptScopes") }}
                </span>
                <span v-else>
                  {{ $t("scopesAlert.requestAdminToAcceptScopes") }}
                </span>
              </v-card-text>
              <v-btn
                v-if="isAdmin && !hasEmailMigrationTaskRunning"
                max-width="200"
                :block="false"
                color="accent"
                class="text-none font-weight-medium text-body-2 white--text"
                elevation="0"
                :href="MARKETPLACE_APP_URL"
                target="_blank"
              >
                {{ $t("action.acceptScopes") }}

                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
          </v-overlay>
          <!-- OVERLAY FOR PLAN -->
          <v-overlay
            :absolute="true"
            color="primary"
            :value="dontHaveSecurityPlanOrHigher"
            opacity="0.9"
            class="pa-5"
          >
            <div class="d-flex flex-column align-center justify-center">
              <v-card-title>
                <v-icon left color="accentSuite">mdi-crown</v-icon>
                <span> {{ $t("common.upsellTitle") }} </span>
              </v-card-title>

              <v-card-text class="font-weight-medium text-center pb-4 mb-6">
                {{
                  freeUsageLimitExceeded
                    ? $t("errorMessages.FREE_USAGE_LIMIT_EXCEEDED")
                    : $t("emailMigration.upsellInvitation")
                }}

                <span class="d-block">
                  {{ $t("common.availableFromSecurity") }}
                </span>
              </v-card-text>
              <v-btn
                color="accent"
                class="text-none font-weight-medium text-body-2 white--text"
                elevation="0"
                @click="goToSelfCheckout"
              >
                {{ $t("common.checkPlans") }}
              </v-btn>
            </div>
          </v-overlay>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  INCREMENTAL_SCOPES,
  MARKETPLACE_APP_URL,
} from "@/helpers/variables/scopes";
import moment from "moment";
import { formatDate } from "@/helpers/services/utils";
import { STATUS } from "@/helpers/variables/backendConstants";

export default {
  name: "MigrateEmail",
  props: {
    user: { type: Object, default: () => {} },
    isPanelOpened: { type: Boolean, default: false },
    dialogMessage: { type: String, default: "" },
    trackMigrationStatus: { type: Boolean, default: false },
  },
  data() {
    return {
      valid: true,
      overlay: false,
      disabled: true,
      panel: [this.isPanelOpened ? 0 : 1],
      loading: false,
      loagingScopes: false,
      snackbarMessage: "",
      snackbarColor: "",
      dialog: false,
      groupEmail: "",
      MARKETPLACE_APP_URL,
      hasEmailMigrationTaskRunning: false,
      dontHaveSecurityPlanOrHigher: false,
      selectedDateOption: moment().subtract(1, "year").format("YYYY-MM-DD"),
      datePickerMenu: false,
      customDate: moment().format("YYYY-MM-DD"),
      includeTrash: false,
      maxDate: moment().format("YYYY-MM-DD"),
      freeUsageLimitExceeded: false,
    };
  },

  computed: {
    ...mapGetters([
      "mainDomain",
      "isAdmin",
      "groupEmails",
      "currentUser",
      "companyHasControl",
      "scopeCode",
      "company",
    ]),

    userHasGmail() {
      return this.user ? this.user.is_gmail_enabled : false;
    },

    formatedCustomDate() {
      return formatDate(this.customDate);
    },

    companyIsInTrial() {
      return this.company.current_plan.valid_status == STATUS.TRIAL;
    },

    options() {
      return [
        {
          text: this.$t("emailMigration.start.past1Month"),
          value: moment().subtract(1, "months").format("YYYY-MM-DD"),
          disabled: false,
          show: true,
        },
        {
          text: this.$t("common.past3Months"),
          value: moment().subtract(3, "months").format("YYYY-MM-DD"),
          disabled: false,
          show: true,
        },
        {
          text: this.$t("common.past6Months"),
          value: moment().subtract(6, "months").format("YYYY-MM-DD"),
          show: true,
        },
        {
          text: this.$t("emailMigration.start.pastYear"),
          value: moment().subtract(1, "year").format("YYYY-MM-DD"),
          disabled: this.companyIsInTrial,
          show: !this.companyIsInTrial,
        },
        {
          text: this.$t("emailMigration.start.customPeriod"),
          value: "custom",
          disabled: this.companyIsInTrial,
          show: !this.companyIsInTrial,
        },
      ].filter((item) => item.show);
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    ...mapActions(["migrateEmails", "checkScopes", "getGoogleWorkspaceGroups"]),

    handleOptionChange(value) {
      if (value !== "custom") {
        this.customDate = null;
        this.datePickerMenu = false;
      }
    },

    openDialog() {
      this.logFirebaseEvent("clicked to open dialog");

      this.dialog = true;
    },

    hasToUpgradePlan() {
      const invalid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];
      const needToUpgradePlan = invalid_status.includes(this.companyHasControl);

      return needToUpgradePlan;
    },

    async initMigration() {
      const { MAIL_SERVICE_NOT_ENABLED } = errorMessages;

      this.logFirebaseEvent("clicked to migrate emails");

      if (!this.user.is_gmail_enabled) {
        this.resetValues();
        this.setSnackBar({
          show: true,
          message: MAIL_SERVICE_NOT_ENABLED,
          color: "warning",
        });
        return;
      }

      const isCustom = this.selectedDateOption === "custom";
      if (isCustom && !this.customDate) {
        return;
      }

      this.loading = true;

      const payload = {
        group: this.groupEmail,
        user_email: this.user.email,
        track_status: this.trackMigrationStatus,
        start_date: isCustom ? this.customDate : this.selectedDateOption,
        include_trash: this.includeTrash,
      };

      const response = await this.migrateEmails(payload);

      this.resetValues();

      if (response.data.code === "HAS_TASK_RUNNING") {
        this.hasEmailMigrationTaskRunning = true;
        this.overlay = true;
      } else if (response.data.code === "MIGRATION_IN_QUEUE") {
        this.$emit("migrationInQueue", response.data.migration);
      } else if (response.data.code == "FREE_USAGE_LIMIT_EXCEEDED") {
        this.freeUsageLimitExceeded = true;
        this.dontHaveSecurityPlanOrHigher = true;
      }
    },

    resetValues() {
      this.loading = false;
      this.dialog = false;
    },

    logFirebaseEvent(clickedWhere) {
      const analytics = getAnalytics();

      logEvent(analytics, "migrate_emails", {
        main_domain: this.mainDomain,
        clicked_where: clickedWhere,
        date: moment().format("DD/MM/YYYY"),
      });
    },

    goToSelfCheckout() {
      const analytics = getAnalytics();

      logEvent(analytics, "go_to_self_checkout", {
        click: "migration",
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: "Conheça os planos na migração de email",
      });

      this.$router.push({ name: "BillingUpgradePlan" });
    },

    async getWorkspaceGroups() {
      this.loagingScopes = true;

      const groupScope = INCREMENTAL_SCOPES.ADMIN_GROUP_MIGRATION;

      if (!this.scopeCode(groupScope)) {
        const payload = {
          scopes: [groupScope],
        };

        await this.checkScopes(payload)
          .then(() => {
            this.overlay = false;
            this.disabled = false;
          })
          .catch(() => {
            this.overlay = true;
            this.disabled = true;
            return;
          })
          .finally(() => {
            this.loagingScopes = false;
          });
      } else if (this.scopeCode(groupScope) === "INVALID_GOOGLE_ACCESS_TOKEN") {
        this.overlay = true;
        this.loagingScopes = false;
        return;
      } else {
        this.loagingScopes = false;
        this.disabled = false;
      }
      await this.getGoogleWorkspaceGroups();
    },
  },

  watch: {
    panel(oldV, newV) {
      if (oldV !== newV && !this.groupEmails.length) {
        this.getWorkspaceGroups();
      }
    },
  },

  async beforeMount() {
    if (this.hasToUpgradePlan()) {
      this.dontHaveSecurityPlanOrHigher = true;
    }

    if (this.dontHaveSecurityPlanOrHigher) {
      return;
    }

    if (this.companyIsInTrial) {
      this.selectedDateOption = moment()
        .subtract(6, "months")
        .format("YYYY-MM-DD");
    }

    if (this.isPanelOpened) {
      this.getWorkspaceGroups();
    }
  },
};
</script>
<style>
.list-item {
  cursor: pointer !important;
}

.list-item:hover {
  background-color: #ed682f1a;
}
</style>
