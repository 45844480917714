<template>
  <div>
    <!-- ASSINATURA E-MAIL-->
    <v-expansion-panels v-model="panel" multiple flat class="ms-1">
      <v-expansion-panel class="outlined">
        <v-progress-linear
          v-if="showLoading == true"
          indeterminate
          color="primary"
        ></v-progress-linear>

        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
          :disabled="disabled"
          >Assinatura de e-mail</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-spacer></v-spacer>
          <v-card-text class="mx-0 pt-0 px-0">
            Gerencie as assinaturas de e-mail para este usuário. Crie e aplique
            modelos de assinatura ou personalize uma manualmente inserindo um
            código HTML. A assinatura será adicionada automaticamente ao final
            das mensagens do Gmail, como um rodapé.
          </v-card-text>

          <UpgradeBanner v-if="!hasConectaSign" dense class="mb-4" />

          <v-col
            v-if="itemSelected && itemSelected !== undefined"
            cols="12"
            md="12"
            class="pa-0"
            style="position: relative"
          >
            <v-tabs v-model="tabs" grow color="accent">
              <v-tab>Visualizar</v-tab>
              <v-tab-item class="px-0 my-3">
                <SignPreview
                  :user="currentUser"
                  :kind="'user'"
                  :view="'conecta_suite'"
                  @changeToEdit="tabs = 1"
                  @missingScopes="overlayScopes = true"
                />
              </v-tab-item>
              <v-tab>Personalizar</v-tab>
              <v-tab-item>
                <v-row class="px-2 pt-3 ma-0 mt-4">
                  <v-col cols="12" sm="6" class="py-0 my-0 px-0">
                    <v-select
                      v-model="selectedEmail"
                      :items="items"
                      label="E-mail"
                      outlined
                      background-color="white"
                      color="#311F50"
                      item-color="accent"
                      item-text="email"
                      item-value="email"
                      :filter="customFilter"
                      :disabled="hasOnlyPrimaryEmail"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0 my-0 px-0"> </v-col>
                </v-row>
                <v-row class="pl-4 pr-2 mt-3 mb-4">
                  Visualizando a assinatura HTML personalizada para
                  <span
                    class="ml-1 font-weight-bold"
                    v-if="currentUser && currentUser.name"
                    >{{ currentUser.name }} ({{ selectedEmail }})</span
                  >.
                </v-row>
                <prism-editor
                  class="my-editor"
                  v-model="itemSelected.signature"
                  :highlight="highlighter"
                  line-numbers
                  style="height: 400px"
                ></prism-editor>

                <v-card-actions>
                  <v-tooltip :disabled="userHasGmail" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="width: fit-content" v-bind="attrs" v-on="on">
                        <v-btn
                          :loading="loading"
                          color="accent"
                          elevation="0"
                          :disabled="!canSaveModel || !userHasGmail"
                          @click="dialogApplySignature = true"
                          class="mt-4 mb-2 px-4 text-none text-body-2 font-weight-medium mr-4"
                        >
                          Salvar assinatura
                        </v-btn>
                      </div>
                    </template>
                    <span v-if="!userHasGmail">
                      A caixa de e-mail deste usuário não está ativa.
                    </span>
                  </v-tooltip>

                  <v-btn
                    :loading="loading"
                    color="grey darken-2"
                    text
                    @click="tabs = 0"
                    class="mt-4 mb-2 text-none text-body-2 font-weight-medium"
                  >
                    Cancelar edição
                  </v-btn>

                  <v-spacer />

                  <span
                    :class="`caption ml-0 ${
                      canSaveModel ? 'grey' : 'red'
                    }--text text--darken-1 font-italic`"
                  >
                    {{ itemSelected.signature?.length }}/10000 caracteres
                  </span>
                </v-card-actions>
              </v-tab-item>
            </v-tabs>

            <!-- OVERLAY FOR SCOPES MISSING  -->
            <OverlayScopes
              :overlay="overlayScopes"
              :functionality="$t('common.emailSignature')"
              dense
            />
          </v-col>

          <HeaderDialog
            :show="dialogApplySignature"
            id="confirm_new_user_signature"
            :title="`${user.name} (${selectedEmail})`"
            subtitle="Confirmar assinatura de e-mail"
            :color="primaryColor"
            @close="dialogApplySignature = false"
            @action="createSignature()"
            :loading="loading"
          >
            <template v-slot:body>
              <p>
                Tem certeza que deseja aplicar essa assinatura no e-mail
                <b>{{ selectedEmail }}</b> do usuário <b>{{ user.name }}</b
                >?
              </p>

              <Alert dense color="warning" class="mb-0">
                Esta alteração pode levar até 10 minutos para ser efetivada.
              </Alert>
            </template>
          </HeaderDialog>

          <Snackbar
            :show="showSnackBar"
            :message="successMessages.saved_signature"
            @close="showSnackBar = false"
          />
          <Snackbar
            :show="showSnackBarError"
            :message="errorMessages.saved_signature"
            color="error"
            @close="showSnackBarError = false"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
// import Prism Editor
import UpgradeBanner from "@/components/billing/upgrade/UpgradeBanner";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
import SignPreview from "@/components/sign/general/SignPreview";
import { mapGetters } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import OverlayScopes from "../general/OverlayScopes.vue";

export default {
  name: "SignatureUser",
  props: {
    user: { type: Object, required: true },
    id: { type: String, required: true },
    email: { type: String, required: true },
    isPanelOpened: { type: Boolean, default: false },
  },
  components: {
    PrismEditor,
    SignPreview,
    UpgradeBanner,
    OverlayScopes,
  },
  data() {
    return {
      errorMessages,
      successMessages,
      tabs: "",
      panel: [this.isPanelOpened ? 0 : 1],
      showSnackBar: false,
      showSnackBarError: false,
      loading: false,
      showLoading: false,
      items: [],
      signature: "",
      selectedEmail: "",
      itemSelected: { email: "", signature: "" },
      dialogApplySignature: false,
      disabled: false,
      overlayScopes: false,
    };
  },

  computed: {
    ...mapGetters(["hasConectaSign", "token", "primaryColor"]),

    currentUser() {
      return this.user;
    },

    userHasGmail() {
      return this.user ? this.user.is_gmail_enabled : false;
    },

    canSaveModel() {
      return this.itemSelected.signature?.length <= 10000;
    },

    hasOnlyPrimaryEmail() {
      return this.items.length === 1;
    },
  },

  async mounted() {
    if (this.userHasGmail) {
      this.$root.$on("eventUpdateSendAs", (data) => {
        this.items = data;
      });
      if (this.isPanelOpened || this.panel.length !== 1) await this.getSendAs();
    }
  },

  methods: {
    // METODOS REQUISICAO
    customFilter(item, queryText) {
      const textOne = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    createSignature() {
      this.showLoading = true;
      let url = process.env.VUE_APP_API_BASE_URL + "/user/signature";
      let params = {
        email: this.selectedEmail,
        id_user: this.id,
        signature: this.itemSelected.signature,
      };
      let auth = { headers: { Authorization: this.token } };

      this.$axios
        .post(url, params, auth)
        .then(async () => {
          await this.getSendAs();
          this.showLoading = false;
          this.dialogApplySignature = false;
          this.showSnackBar = true;
          this.showSnackBarError = false;
        })
        .catch((err) => {
          this.showLoading = false;
          this.dialogApplySignature = false;
          this.showSnackBarError = true;
          console.error("createSignature()", err);
        });
    },

    getSendAs() {
      this.showLoading = true;
      this.disabled = true;
      let url = process.env.VUE_APP_API_BASE_URL + "/user/sendas";
      let params = { id_user: this.id };

      this.$axios
        .get(url, {
          params: {
            params,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((response) => {
          let sendAsEmailList = response.data.sendAs;
          this.items = sendAsEmailList.map((listItem) => ({
            email: listItem.sendAsEmail,
            signature: listItem.signature || "",
          }));
          this.disabled = false;

          this.selectedEmail = this.email;
          this.showLoading = false;
        })
        .catch((err) => {
          console.error("getSendAs()", err);
          this.items = [{ email: this.user.email, signature: "" }];
          this.selectedEmail = this.user.email;
          this.showLoading = false;
          this.disabled = true;
        });
    },

    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },

  watch: {
    selectedEmail() {
      this.itemSelected = this.items.find(
        (r) => r.email == this.selectedEmail
      ) || { email: this.selectedEmail, signature: "" };
    },

    itemSelected() {
      if (this.itemSelected.signature) {
        this.showRealTimePreview = true;
      }
    },

    items() {
      if (this.hasOnlyPrimaryEmail) {
        this.selectedEmail = this.items[0].email;
      }
    },

    async email() {
      this.showSnackBar = false;
      this.showSnackBarError = false;
      this.loading = false;
      this.showLoading = false;
      this.items = [];
      this.signature = "";
      this.selectedEmail = "";
      this.itemSelected = { email: "", signature: "" };

      await this.getSendAs();
    },

    panel() {
      if (this.panel.length > 1 && this.items.length === 0) {
        this.getSendAs();
      }
    },
  },
};
</script>

<style scoped>
.row_pointer {
  cursor: pointer;
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #f9f9f9;
  color: #444;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>
